import React from "react"

import SEO from "../components/Base/SEO"
import Form from "../components/Enquiry/Form"
import Layout from "../components/Base/Layout"

export default function (props) {
  const slug = props[`*`]
  return (
    <Layout>
      <SEO title={"Sheer Analytics and Insights Request Brochure"} />
      <Form headingText={"brochure"} linkText={"RequestBrochure"} slug={slug} />
    </Layout>
  )
}
